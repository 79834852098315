.box-quiz{
    position: relative;
    height: max-content;
    background: #FFFFFF;
    border: 7px solid #DBA720;
    box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    text-align: left;
    padding-bottom: 25px;
    padding-top: 25px;
    color: #243C39 !important;
}

.pourcentage{
    position: relative;
    margin-top: 20px;
    border-radius: 25px;
    left: 50%;
    transform: translateX(-50%);
    height: 15px;
    background-color: #243C39;
    width: 80%;
    padding: 2px;
}

.question{
    font-weight: bold !important;
    margin: 40px 0px 40px 0px !important;
}

.content{
    width: 80%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
}
.MuiSlider-root{
    margin-top:16px !important;
}
.label-start{
    min-width: 15%;
}
.label-end{
    min-width: 15%;
}
.label-end div{
    width: 50% !important;
    position: relative;
    left: 100%;
    transform: translateX(-100%);
}
.input-quiz{
    margin-top: 15px !important;
}
.button-suivant{
    background: linear-gradient(90deg, #AD8619 -1.98%, #DBA720 24.43%, #FFDA25 59.82%, #FAE891 99.99%);
    border-radius: 50px !important;
    padding: 15px !important;
    padding-right: 50px !important;
    padding-left: 50px !important;
    margin-top: 20px !important;
    width: max-content;
    font-weight: bold;
}
.button-precedent{
    background-color: #083936 !important;
    border-radius: 50px !important;
    padding: 15px !important;
    padding-right: 50px !important;
    padding-left: 50px !important;
    margin-top: 20px !important;
    width: max-content;
    color: #fff !important;
    margin-right: 20px !important;
    font-weight: bold;
}