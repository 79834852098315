.box-card-contact{
    background: #FFF9F9;
    border: 7px solid #DBA720;
    box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    margin-bottom: 30px;
    width: 80%;
    padding-top: 15px;
    padding-bottom: 15px;
    transition: transform .2s;
}
@media only screen and (max-width: 600px) {
    .box-card-contact{
        width: 100% !important;
    }
    .box-card-contact img{
        position: relative;
        width: 100% !important;
        top: 50%;
        transform: translateY(-50%);
    }
    .box-card-icon svg{
        width:1em !important;
    }
    .box-card-icon p{
        margin-left: 2px !important;
    }
    .box-compress{
        transform: scale(0.9);
    }
    .contact-form{
        width: 100% !important;
    }
  }
.box-card-contact:hover{
    transform: scale(1.1);
}
.box-card-contact a{
    color: black !important;
}
.box-card-icon p{
    margin-top: 0;
    margin-left: 10px;
}
.card-contact-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.card-contact-text .paragraphe-name p{
    margin-bottom: 0px !important;
}
.card-contact-text p{
    margin-bottom: 8px !important;
    margin-top: 0px !important;
}
.contact-form{
    width: 70%;
}
.contact-form label{
    color:#fff;
    margin-bottom: 10px;
    font-weight: bold !important;
}
.contact-form input{
    color:"#fff";
    height: 45px;
    outline: none;
    border: 1px solid #949494;
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 15px;
    font-family: 'Raleway', sans-serif !important;
    font-weight: bold !important;
    margin-bottom: 25px;
}
.contact-form input:focus{
    border: 4px solid #FFDA25 !important;
}
.contact-form input:required{
    border: 1px solid #FF0000;
    background: #FFCDCD;
}
.contact-form textarea:focus{
    border: 4px solid #FFDA25 !important;
}
.contact-form textarea:required{
    border: 1px solid #FF0000;
    background: #FFCDCD;
}
.contact-form textarea{
    color:"#fff";
    height: 250px;
    min-width: 100%;
    max-width: 100%;
    outline: none;
    border: 1px solid #949494;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 20px;
    padding-left: 15px;
    padding-top: 15px;
    font-family: 'Raleway', sans-serif !important;
    font-weight: bold !important;
}