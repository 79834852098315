.title{
    margin-bottom: 20px !important;
    font-weight: bold !important;
}
.divider-jaune{
    border: 2px solid #FFDA25;
    border-radius: 2px;
}
.divider-bleu{
    border: 2px solid #25AE84;
    border-radius: 2px;
}
.center{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}
.left{
    position: relative;
    left: 0;
}
.right{
    position: relative;
    left: 100%;
    transform: translateX(-100%);
}
.titre-bloc{
    margin-bottom: 50px !important;
}
.titre{
    margin-bottom: 20px !important;
    font-weight: bold !important;
}