@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@800&display=swap');


:root{ scrollbar-width: none !important } 


body::-webkit-scrollbar{
  width: 0 !important;
  }
body{
  background:linear-gradient(#243C39 7.91%, #27A783 57.28%, #25AE84 78.86%, #25AE84 96.11%, #25AE84 96.12%);
  background-repeat: no-repeat; 
  font-family: 'Raleway', sans-serif !important;
  overflow: -moz-scrollbars-none !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none;
}
pre{
  white-space: pre-wrap;
  white-space: -moz-pre-wrap !important;
  white-space: -o-pre-wrap !important;
  word-wrap: break-word !important;
  width: 100%;
}
button{
  display: inline !important;
  text-transform: lowercase !important;
  font-size: 1rem !important;
  font-weight: bold !important;
}
button::first-letter{
  text-transform: uppercase !important;
}
h1,h2,h3,h4,h5,h6, button {
  font-weight: bold !important;
}
.link{
  display: inline-block;
  font-weight: bold !important;
}
a{
  text-decoration: none !important;
  font-weight: 500 !important;
}
label{
  display: initial !important;
}
* {
  -webkit-box-sizing: inherit;
     -moz-box-sizing: inherit;
          box-sizing: inherit;
}
*:before,
*:after {
  -webkit-box-sizing: inherit;
     -moz-box-sizing: inherit;
          box-sizing: inherit;
}
a:hover{
  color: inherit !important;
  font-weight: 700 !important;
}
h1,h2,h3,h4,h5,h6,p,a,span,pre,input,label,textarea{
  font-family: 'Raleway', sans-serif !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
a{
  text-decoration: none;
  color:#fff;
}
iframe body #embedCategoryLink a{
  display: none !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.sticky {
  position: fixed !important;
  background: #27A783 !important;
  padding: 0 !important;
  padding-right:50px !important;
  padding-left:50px !important;
  z-index: 1398 !important;
}
.button-devis-float{
  position: fixed;
  top: 50%;
  left: 0px;
  background: linear-gradient(165.36deg, #AD8619 -1.98%, #DBA720 24.43%, #FFDA25 59.82%, #FAE891 99.99%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 40px 40px 0px !important;
  border: 0;
  width: 25px;
  height: 150px;
  outline:none;
  cursor: pointer;
  transform: translateY(-50%);
}
.button-devis-float span{
  display:block;
    width: 150px;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-75px) rotate(90deg);
}
.footer p {
  font-size: 14px !important;
}
.copyright a {
  font-size: 14px !important;
}
.demoPage{
  background-color: #fff;
}
.mybook{
  display: flex;
  justify-content: center;
}
.link-mobile {
  color: #000 !important;
  font-weight: bold;
  padding: 20px !important;
  padding-left: 50px !important;
}
.MuiTab-wrapper {
  text-transform: capitalize;
}

#upload-photo {
  opacity: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}
#cv-label{
  background: linear-gradient(1.99deg, #243C39 0%, #25AE84 100%);
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 100px;
color: #FFDA25;
font-family: 'Raleway', sans-serif !important;
font-style: normal;
font-weight: 800;
font-size: 1rem !important;
text-align: center;
padding: 10px 40px;
margin: 0;
}
#cv-label:hover{
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.2) !important;
}

.div-grid ul{
  text-align: left;
}

.div-grid ul li {
}

.DropDown{
  padding-right: 30px;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.dropdown-content{
  display: flex;
  flex-direction: column;
  position: absolute;
  background: #25AE84;
  color: black;
  top: calc(100% + 20px);
  padding: 0;
  width: 100%;
  min-width: 150px;
  height: 0px;
  overflow: hidden;
  transition: 1s;
  border-radius: 5px;
}
.dropdown-content a{
 color:#fff !important;
 border-bottom: 1px solid #083936;
 padding: 10px 20px 10px 20px;
 width: 100%;
 text-align: center;
 white-space: nowrap;
}

.dropdown-content-show{
  display: flex;
  flex-direction: column;
}

.desc:nth-child(1n) {
  
font-family: Raleway;
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 42px;
text-align: center;

color: #FFFFFF;
}

.newsletter{
  background-color: #fff;
  max-width: 800px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50px;
  padding-left: 20px;
  padding-right: 20px;
  height: 60px;
  width: 100%;
  margin-top: 25px;
}
.newsletter input::placeholder{
  opacity: 0;
}
.newsletter input{
  border: 0 !important;
  background-color: rgba(0, 0, 0, 0);
  outline: none;
  width: 60%;

  font-family: Raleway;
font-style: normal;
font-weight: 800;
font-size: 18px;
line-height: 28px;

color: #083936;

opacity: 0.6;
}
.newsletter button {
  font-family: Raleway;
font-style: normal;
font-weight: 800;
font-size: 18px;
line-height: 40px;
text-align: center;
width: 40%;
color: #ECA800;
position: relative;
opacity: 0.6;
height: 80%;
top: 50%;
transform: translateY(-50%);
padding-left: 20px;
border: none;
border-left: 5px solid #ECA800;
overflow: hidden;
transition: 0.5s;
background: none;
}

.newsletter .disable{
  color: lightgrey !important;
  border-left: 5px solid lightgrey !important;
  cursor: not-allowed;
}

.newsletter button:hover{
  color: #1fd39d;
  border-left: 5px solid #1fd39d;
}

.LastArticle{
  position: relative;
  padding: 50px;
  width: 100%;
  max-width: 1500px;
  height: 450px;
  border-radius: 15px;
  background-repeat: no-repeat;
  overflow: hidden;
  background-size: cover;
  background-position: 50% 50%;
  left: 50%;
  transform: translateX(-50%);
}

.LastArticle .header-lastArticle h2{
  max-width: 70%;
}

.LastArticle .hashtag{
  position: absolute;
  top: 0;
  right: 0;
}

.MuiDrawer-paperAnchorLeft{
  background:linear-gradient(90deg, #AD8619 0%, #DBA720 25.9%, #FFDA25 60.61%, #FAE891 99.99%)
}

.LastArticle .header-lastArticle .hashtag span{
  padding: 10px;
  padding-left: 25px;
  padding-right: 25px;

  background: #1FD39D;
  border-radius: 100px;

  font-family: Raleway;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  margin-right: 15px;
  
}

.LastArticle .filter-article{
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 218, 37, 0.8);
  height: 100%;
  width: 100%;
  border-radius: 15px;
  z-index: 1;
}

.LastArticle .divider{
  width: 30%;
  height: 5px;
  background-color:  #DBA720;
  margin-top: 30px;
}

.LastArticle .LastArticle-content{
  margin-top: 30px;
  text-align: left !important;
}

.LastArticle .LastArticle-content p {
  text-align: left !important;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;

  color: #083936;

  display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.LastArticle .LastArticle-content button{
  border: 2px solid #083936;
  box-sizing: border-box;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0) !important;
  padding: 10px 50px 10px 50px !important;
  margin-top: 30px;
  transition: 0.5s;
}

.LastArticle .LastArticle-content button:hover{
  transform: scale(1.1);
  box-shadow: none !important; 
}

@media screen and (max-width: 992px) {
.LastArticle{
    height: auto;
  }
}

@media screen and (max-width: 600px) {
  .newsletter .disable{
    border-left: none !important;
  }
  .newsletter{
    flex-direction: column;
    background: none;
    height: max-content;
  }
  .newsletter input{
    background: #fff;
    opacity: 1;
    border-radius: 50px;
    padding-left: 20px;
    padding-right: 20px;
    height: 60px;
    width: 100%;
  }
  .newsletter button{
    font-size: 16px;
    margin-top: 15px;
    padding: 0;
    line-height: normal;
    top: auto;
    left: 100%;
    transform: translateX(-100%);
    background: linear-gradient(90deg, #AD8619 -1.98%, #DBA720 24.43%, #FFDA25 59.82%, #FAE891 99.99%);
    background-color: rgba(0, 0, 0, 0);
    border-radius: 50px !important;
    padding: 10px !important;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
    color: #000;
    min-width: 64px;
    padding-right: 20px !important;
    padding-left: 20px !important;  
    opacity: 1;
    border: none !important;
    width: max-content;
  }

  .newsletter button:hover {
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  }

  .LastArticle{
    padding: 25px;
    background-size: cover;
  }
  
  .LastArticle .header-lastArticle h2{
    max-width: 100%;
    font-size: 22px;
  }


  .LastArticle .header-lastArticle .hashtag span {
    position: relative;
    padding: 10px;

  background: #1FD39D;
  border-radius: 100px;

  font-family: Raleway;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  margin-right: 15px;
  }

  .LastArticle .LastArticle-content p {
    display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
  }
}
.multiSelect{
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  width: 100%;
  color:#083936;
  font-weight: bold;
}

.multiSelect .css-1s2u09g-control, .multiSelect .css-1hb7zxy-IndicatorsContainer{
  border-radius: 100px !important;
}

.multiSelect .css-1pahdxg-control{
  border-radius: 100px !important;
  border-color: #AD8619 !important;
  box-shadow: 0 0 0 1px #AD8619;
}

.css-12jo7m5{
  color: #ECA800 !important;
  font-family: Raleway !important;
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 16px !important;
  
}


// .multiSelect__option:focus, .multiSelect__option:hover{
//   background: #AD8619 !important;
// }

// .MuiButtonBase-root{
//   border: solid red 1px !important;
// }

.css-1rhbuit-multiValue{
  background-color: rgba(0, 0, 0, 0) !important;
}


.list-article{

}

.list-article .image{
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  width: 25%;
  height: 300px;
  border-radius: 15px;
}

.list-article .image img{
  max-height: 800px !important;
  height: 500px;
}

.bloc-text{
  margin-left: 40px;
  width: 75%;
}

.list-article .marge{
  margin-bottom: 25px;
}

.list-article .title{
font-family: Raleway;
font-style: normal;
font-weight: 800;
font-size: 30px;
line-height: 42px;

color: #FBFAFD;

display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
}

.list-article .hastag-catego{
  background: #1FD39D;
  border-radius: 100px;
  width: max-content;
  padding: 10px 25px 10px 25px;
  width: 20%;
  min-width:130px;
  height: max-content;
  font-family: Raleway;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  text-align: center;
  margin-right: 15px;
  color: #243C39;
}

.list-article .description{
  font-family: Raleway;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 21px;

color: #FBFAFD;

display: -webkit-box;
-webkit-line-clamp: 5;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
}


.list-article  button{
  border: 2px solid #FFDA25;
  box-sizing: border-box;
  border-radius: 100px;
  background: rgba(0, 0, 0, 0) !important;
  padding: 10px 50px 10px 50px !important;
  transition: 0.5s;
  color:#FFDA25 ;
}

.list-article  button:hover{
  transform: scale(1.1);
  box-shadow: none !important; 
}

.article-time{
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 35px;
  
  color: #FFDA25;
}

.iconArticle{
  width: 1.5em !important;
  height: 1.5em !important;
}

.multiSelect *{
  z-index: 2 !important;
  position: relative;
}



@media screen and (max-width: 992px) {
  .list-article{
    flex-direction: column;
  }
  .list-article img{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    max-width: 50%;
  }
  .list-article .header{
    flex-direction: column-reverse !important;
    text-align: center;
  }

  .list-article .hastag-catego{
    // position: relative;
    // left: 50%;
    // transform: translateX(-50%);
  }

  .list-article .description{
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .article-filter{
    flex-direction: column;
  }

  .article-filter .filter-child{
    width: 100% !important;
    z-index: 2;
  }

  .list-article .image{
   width: 100%;
  }
  
  .bloc-text{
    margin-top: 25px;
    width: 100%;
    margin-left: 0px;
  }


  .list-article .hastag-catego{
    // position: relative;
    // left: 50%;
    // transform: translateX(-50%);
  }

  .list-article .description{
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  .list-article .link{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .list-article .title{
    display: -webkit-box;
    -webkit-line-clamp: none;
    -webkit-box-orient: none;
    overflow: none;
    text-overflow: none;

    text-align: center;
    }
}

@media screen and (max-width: 600px) {
  .list-article{
    flex-direction: column;
  }
  .list-article img{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    max-width: 70%;
  }
  .list-article .header{
    flex-direction: column-reverse !important;
    text-align: center;
  }

  .list-article .hastag-catego{
    // position: relative;
    // left: 50%;
    // transform: translateX(-50%);
  }

  .list-article .description{
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  .list-article .link{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .list-article .title{
    display: -webkit-box;
    -webkit-line-clamp: none;
    -webkit-box-orient: none;
    overflow: none;
    text-overflow: none;

    text-align: center;
    }
    .bloc-text{
      margin-left: 0px;
    }
    .list-article .image{
      width: 100%;
     }
     
     .bloc-text{
       width: 100%;
       margin-left: 0px;
     }
     .div-hastag-catego{
       position: relative;
     }
     .newsletter button{
      left: 50%;
      transform: translateX(-50%);  
     }
     .newsletter input::placeholder{
      opacity: 1;
    }
     .newsletter button:hover{
       color: inherit !important;
     }
}

.ariane{
  margin-top: 25px !important;
}
.ariane a, .ariane p, .ariane{
  color: #FFDA25 !important;
}