.MuiButton-contained:hover{
    box-shadow: none !important;
}
.MuiButton-contained{
    box-shadow: none !important;
}


.button{
    position: relative;
    background: linear-gradient(90deg, #AD8619 -1.98%, #DBA720 24.43%, #FFDA25 59.82%, #FAE891 99.99%);
    border-radius: 50px !important;
    padding: 10px !important;
    padding-right: 40px !important;
    padding-left: 40px !important;
    margin-top: 20px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
}
.button2{
    position: relative;
    background: #27A783 !important;
    border-radius: 50px !important;
    padding: 10px !important;
    padding-right: 40px !important;
    padding-left: 40px !important;
    margin-top: 20px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
    color: #fff !important;
}

.button:hover{
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25) !important;     
}
.button2:hover{
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25) !important;     
}
.right{
    position: relative;
    left: 100%;
    transform: translateX(-100%);
}
.left{
    
}
.center{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}
.bottom-arrow{
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
}
.bottom-arrow img{
    transition: transform .2s;
}
.bottom-arrow img:hover{
    transform: scale(1.1);
}

@media only screen and (max-width: 600px) {
    .button{
        padding-right: 20px !important;
        padding-left: 20px !important;
    }
}