.quiNousSomme-bloc{
    color: #243C39;
}
.bloc-padding{
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px;
}
.bloc-jaune{
    background: linear-gradient(95deg, #AD8619 0%, #DBA720 25.9%, #FFDA25 60.61%, #FAE891 99.99%);
}

.quiNousSomme-text{
    position: relative;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}
@media only screen and (max-width: 600px) {
    .quiNousSomme-text{
        width: 100% !important;
    }
  }
.quiNousSomme-titre{
    margin-bottom: 20px !important;
}
.quiNousSomme-bottom-arrow{
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
}

.expertise-bloc{
    position: relative;
    color: #243C39;
    padding-top: 100px;
    padding-bottom: 100px;
}

.Expertise-card{
    text-align: center;
    position: relative;
    max-height: 320px;
    margin-bottom: 80px !important;
    transition: transform .2s;
}
.Expertise-card:hover{
    transform: scale(1.1);
}
.expertise-Box{
    position: relative;
    background: #FBFAFD;
    left: 50%;
    transform: translateX(-50%);
    padding: 15px;
    padding-top: 25px;
    padding-bottom: 25px;
    width: 95%;
    justify-content: center;
    border: 7px solid #DBA720;
    border-radius: 15px;
    box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.25);
    margin-top: 20px;
    height: 100%;
}
.expertise-Box .button{
    margin-top: 0px !important;
}
@media only screen and (max-width: 600px) {
    .expertise-Box{
        width: 100%;
    }
  }
.expertise-Box div{
    margin-bottom: 10px;
}
.expertise-button{
    background: linear-gradient(90deg, #AD8619 -1.98%, #DBA720 24.43%, #FFDA25 59.82%, #FAE891 99.99%);
    border-radius: 50px !important;
    padding: 15px !important;
    min-width: 80% !important;
    margin-top: 20px !important;
}