.article{

}

.article .header-image{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    max-width: 100%;
}

.article .iconArticle{
    width: 1.5em ;
    height: 1.5em;
}

.article .author-avatar{
    max-height: 300px;
}

.article .description{
    margin-top: 50px;
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;

    color: #FFFFFF;
}

.article .description a{
    color: #FFDA25;
}


.article .name, .post{
    padding-left:15px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 35px;

    color: #FFDA25;
}

.article .description-author{
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 35px;

    color: #FFFFFF;
    flex-grow: 1;
    padding-left:15px;
}

.section-author{
    display: flex;
}
@media screen and (max-width: 992px) {
    .section-author{
        flex-direction: column-reverse;
    }
    .article .author-avatar{
        left: 50%;
        position: relative;
        transform: translateX(-50%);
        max-width: 50%;
        max-height: none !important ;
    }
}

@media screen and (max-width: 600px) {
    .section-author{
        flex-direction: column-reverse;
    }
    .article .author-avatar{
        left: 50%;
        position: relative;
        transform: translateX(-50%);
        max-width: 70%;
        max-height: none !important ;
    }
    .iconArticle{
        width: 1em !important;
        height: 1em !important;
    }

    .article .description-author{
        padding-left: 0px;
    }
    .article .name, .article .post{
        padding-left: 0px;
    }
}


.iconArticle:hover{
    transform: scale(1.1);
}
