.header-bloc{
    position: relative;
    color: #fff;
    padding-top: 100px;
    padding-bottom: 100px;
}
.header-text{
    position: relative;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}
@media only screen and (max-width: 600px) {
    .header-text{
        width: 100% !important;
    }
  }
.header-titre{
    margin-bottom: 20px !important;
}
.header-button{
    position: relative;
    left: 100%;
    transform: translateX(-100%);
    background: linear-gradient(90deg, #AD8619 -1.98%, #DBA720 24.43%, #FFDA25 59.82%, #FAE891 99.99%);
    border-radius: 50px !important;
    padding: 15px !important;
    min-width: 50% !important;
    margin-top: 20px !important;
}
.header-button:hover{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.header-bottom-arrow{
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
}

