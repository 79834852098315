.bloc-project{
    width:90%; 
    height: 100%;
    background:#FBFAFD; 
    border: 7px solid #DBA720; 
    border-radius: 15px;
    padding:10px;
    padding-top: 25px;
    padding-bottom: 25px;
    text-align:center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    cursor: pointer;
}
.bloc-project img {
    max-height: 120px;
    max-width: 60%;
    position: relative;
}
.bloc-project h4 {
    width: 70%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
   -webkit-box-orient: vertical;
}
.bloc-project p {
    width: 90%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 500;
    font-size: 16px;
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 4; /* number of lines to show */
   -webkit-box-orient: vertical;
}
.bloc-project .hover-project{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: -100%;
    transition: 1s;
    background-color: rgba(236, 168, 0, 0.8);
    color:#fff;
    cursor: pointer;
}
.bloc-project .hover-project svg{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 35px;
}
.bloc-project:hover .hover-project{
    left: 0;
}
.image-modal{
    position: relative;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
}
.carousel-inner {
    height: 100%;
}
.carousel-indicators li{
    height: 10px;
    width: 10px;
    background-color: #243C39;
    border-radius: 15px !important;
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
}
.carousel-indicators .active{
    background-color: #25AE84 !important;
}
.carousel-control-next:hover .carousel-control-next-icon:after{
    color: #25AE84 !important;
}
.carousel-control-prev:hover .carousel-control-prev-icon:after {
    color: #25AE84 !important;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px;
  width: 100px;
  background-size: 100%, 100%;
  outline: #25AE84;
  background-image: none;
}

.carousel-control-next-icon:after
{
  content: '>';
  font-size: 25px;
  color: #000;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 25px;
  color: #000;
}
.MuiTabs-flexContainer{
    background: #DBA720;
}
.MuiTabs-flexContainer button{
    border-right: solid #7E5A00 1px;
    border-left: solid #7E5A00 1px;
}
.MuiTabs-flexContainer span{
    color: #243C39;
}
.Mui-selected{
    background-color: #FFDA25 !important;
}
.MuiTab-textColorPrimary.Mui-selected {
    color: #25AE84 !important;
}
.MuiTabs-indicator{
    background-color: rgba(236, 168, 0, 0) !important;
}
.close-button{
    position:absolute;
    top: 15px;
    right: 15px;
    cursor:pointer;
    color:#fff;
}
.close-button:hover {
    color: #25AE84 !important;
}

.MuiGrid-root header {
    border: none; 
    background: rgba(0,0,0,0);
    box-shadow: none;
}

.MuiTabs-root {
    border-radius: 10px !important;
    background: rgba(0,0,0,0);
}
div[data-testid="core__inner-pages"] {
    overflow: hidden;
    -ms-overflow-style: none !important;
    scrollbar-width: none;
    width: calc(100% + 17px);
    display: block;
    background-color: rgba(0,0,0,0);
  }
.rpv-core__page-layer{
    margin: 0 !important;
    left: 50%;
    transform: translateX(-50%);
}
.rpv-core__canvas-layer{
}
.rpv-core__canvas-layer canvas{
}
  .rpv-core__inner-page{
      background: transparent !important;
  }

.video video{
    position: relative;
    max-height: 600px;
    max-width: 100%;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
}
