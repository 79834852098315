.devis{
    position: fixed;
    top: 0;
    left: 0;
    background:linear-gradient(#243C39 7.91%, #27A783 57.28%, #25AE84 78.86%, #25AE84 96.11%, #25AE84 96.12%);
    z-index: 2000;
    overflow-y: scroll;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    scrollbar-width: none !important 
}
.devis::-webkit-scrollbar{
    width: 0 !important
}
body::-webkit-scrollbar{
width: 0;
}
body{
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
}
#devis {
    height: 100vh;
    width: 0%;
    transition: 0.5s;
}
.bloc-devis{
    padding: 25px;
    position: relative;
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
}
.devis label{
    position: relative;
    color:#fff;
    margin-bottom: 50px !important;
    font-weight: bold !important;
}
.devis textarea{
    color:"#fff";
    min-height: 100px;
    outline: none;
    border: 1px solid #949494;
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    padding-left: 15px;
    padding-top: 15px;
    font-family: 'Raleway', sans-serif !important;
    font-weight: bold !important;
}
.devis textarea:focus{
    border: 4px solid #FFDA25 !important;
}
.devis input{
    color:"#fff";
    height: 45px;
    outline: none;
    border: 1px solid #949494;
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 20px;
    width: 100%;
    padding-left: 15px;
    font-family: 'Raleway', sans-serif !important;
    font-weight: bold !important;
}
.devis input:focus{
    border: 4px solid #FFDA25 !important;
}
.devis input:required{
    border: 1px solid #FF0000;
    background: #FFCDCD;
}
.devis input::placeholder{
    color:#CE1124

}
.button-devis{
    background: #FFDA25 !important;
    border: 4px solid #FFFFFF !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    margin-right: 20px !important;
    min-width: 200px !important;
    margin-top: 5px !important;
    margin-bottom: 20px !important;
}
.button-devis-off{
    background: #FFDA25 !important;
    border: 1px solid #949494 !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    margin-right: 20px !important;
    min-width: 200px !important;
    margin-top: 5px !important;
    margin-bottom: 20px !important;
}
.button-retour{
    position: absolute;
    top: 50%;
    right: 0;
    background: linear-gradient(165.36deg, #AD8619 -1.98%, #DBA720 24.43%, #FFDA25 59.82%, #FAE891 99.99%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 40px 0px 0px 40px;
    border: 0;
    width: 36px;
    height: 150px;
    outline:none;
    cursor: pointer;
    transform: translateY(-50%);
    padding-right: 12px;
}
.button-retour span{
    display:block;
    width: 150px;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-75px) rotate(270deg);
}