footer{
    background: #243C39;
    position: relative;
    width: 100%;
    color: #fff;
}
.footer{
    border-bottom: 1px solid #FFDA25;
    padding-bottom: 50px;
    padding-top: 50px;
}
.icon{
    color: #FFDA25;
    width: 1em;
    height: 1em;
}
@media only screen and (max-width: 600px) {
    .icon{
        width: 2em !important;
        height: 2em !important;
    }
    .icon-info{
        width: 1em !important;
        height: 1em !important;
    }
    .logo-footer{
        text-align: center;
    }
  }
.info{
    margin-top: 0;
    margin-left: 10px;
}
.copyright{
    color: #FFDA25 !important;
    padding-top: 25px;
    padding-bottom: 15px;
}
.copyright a{
    cursor: pointer;
    color: #FFDA25 !important;
}
.around{
justify-content: space-between;
}