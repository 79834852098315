.div-grid{
    margin-bottom: 50px !important;
    transition: transform .2s;
}
.div-grid:hover{
    transform: scale(1.1);
}
.box{
    border: 5px solid #DBA720;
    border-radius: 15px;
    box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.25);
    background: #FBFAFD;
    width: 100%;
    height: 100%;
    min-height: 400px;
    padding: 25px;
    text-align: center;
}
.pilier-icon{
    position: relative;
    height: 60px;
}
.pilier-icon img{
    height: 100% !important;
}
.marge-bottom{
    margin-bottom: 15px;
}

