.carousel-box-image{
    text-align: center;
}
.carousel-box-image img{
    width: 80%;
}
.testimonial-footer{
font-weight: bold !important;
text-align: right;
font-size: 1.2rem !important;
}
.dots-stepper{
    position: relative !important;
    text-align: center !important;
    background: rgba(0,0,0,0) !important;
    width: fit-content;
    left: 50% !important;
    transform: translateX(-50%) !important;
}